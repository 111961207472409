.studio-section {
    position: relative;
    margin-top: 330vh;
}

.studio-container {
    gap: 120px;
}

.studio-title-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    padding: 0 5.4vw;
    text-align: left;
    width: 100%;
    position: relative;
    z-index: 3;
}

.studio-content-container {
    display: flex;
    color: var(--faded-dark-text);

    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 5.4vw;
    position: relative;
    z-index: 3;
    height: 100%;
    width: -webkit-fill-available;
}

.studio-image-container {
    height: 100%;
    width: -webkit-fill-available;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.studio-text-element {
    position: relative;
    z-index: 3;
    color: var(--faded-dark-text);
    padding: 50px 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 50px;
    text-align: left;
    width: -webkit-fill-available;
    font-size: 22px;
    font-weight: 400;
}

.studio-scroll-text {
    width: 310px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}




/* ===================== */
/* Nest Hub */
/* ===================== */
@media (max-width: 1100px) {
    .studio-container {
        gap: 50px;
    }
}


/* ===================== */
/* Portait Mode */
/* ===================== */
@media (max-width: 1100px) and (orientation: portrait)  {
    .studio-container {
        align-items: center;
        justify-content: center;
    }

    .studio-title-wrapper {
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: auto;
        flex-direction: column;
        text-align: center;
        padding: 0;
    }

    .feature-title {
        font-size: 40px;
    }

    .feature-title-2 {
        font-size: 50px;
    }

    .studio-content-container {
        flex-direction: column;
    }

    .studio-image-container {
        height: 400px;
        justify-content: center;
    }

    .studio-scroll-text {
        font-size: 22px;
        top: 150%;
    }

    .LP-studio-imposter-text {
        width: 390px;
    }

    .LP-studio-agile-text {
        width: 390px;
    }
}


/* ===================== */
/* Portait iPad Pro */
/* ===================== */
@media (max-width: 1100px) and (min-height: 1200px)  {
    .studio-title-wrapper {
        width: 500px;
    }

    .feature-title {
        font-size: 50px;
    }

    .feature-title-2 {
        font-size: 60px;
    }

    .studio-image-container {
        height: 800px;
    }

    .studio-image {
        height: 700px;
    }

    .studio-scroll-text {
        top: 150%;
        font-size: 28px;
    }
}
