/* Main container */
.fib-result {
  font-family: Inter, sans-serif;
  border-radius: 10px;
  opacity: 0; /* For GSAP animation */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header */
.fib-result__header {
  text-align: center;
}

.fib-result__header h2 {
  margin: 0;
  font-size: 1.5em;
}

/* Question Text */
.fib-result__question {
  margin-top: 20px;
  font-size: 1.1em;
  font-weight: 600;
  text-align: center;
}

/* User Answer */
.fib-result__user-answer {
  width: 100%;
  max-width: 600px;
  padding: 15px 20px;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  transition: border-color 0.3s ease;
  text-align: center;
  color: #ff0000;
}

/* Best Answer (when user is wrong) */
.fib-result__best-answer {
  color: #4bda41;
  font-size: 1.2em;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

/* Explanation */
.fib-result__explanation {
  border-top: 2px solid #ddd;
  padding-top: 20px;
  text-align: center;
  color: #555;
  width: 100%;
  max-width: 600px;
}

.fib-result__explanation--correct {
  margin-top: 20px;
}

/* Difficulty-specific styling for user answer container */
.fib-result__user-answer--novice {
  border-color: #b19cd9;
  box-shadow: 0px 2px 15px rgba(177, 156, 217, 0.4);
}

.fib-result__user-answer--apprentice {
  border-color: #99ccff;
  box-shadow: 0px 2px 15px rgba(153, 204, 255, 0.4);
}

.fib-result__user-answer--adept {
  border-color: #91d8cc;
  box-shadow: 0px 2px 15px rgba(145, 216, 204, 0.4);
}

.fib-result__user-answer--expert {
  border-color: #ff9999;
  box-shadow: 0px 2px 15px rgba(255, 153, 153, 0.4);
}

.fib-result__user-answer--master {
  border-color: #ffe066;
  box-shadow: 0px 2px 15px rgba(255, 224, 102, 0.4);
}

/* When the answer is correct, override text color */
.fib-result__user-answer--correct p {
  color: #00c700;
}

