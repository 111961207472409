/* Sidebar.css */
.sidebar {
    display: flex;
    padding: 10px;
    position: relative;
}

.sidebar-content {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    gap: 7px;
}

.sidebar-icon-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 230px;
}

.sidebar-divider {
    content: '';
    display: block;
    height: 2px;
    background: linear-gradient(180deg,#cecece 50%,#fff 0);
}

.sidebar-close-icon-container,
.sidebar-chat-icon-container,
.sidebar-open-icon-container {
    position: relative; /* Positioning context for tooltips */
}

.sidebar-icon {
    cursor: pointer;
    color: #5d5d5d;
}

.chat-svg-icon,
.settings-svg-icon {
    border-radius: 10px;
    height: 1.6rem;
    padding: 8px;
    width: 1.6rem;
    display: flex;
}

.chat-svg-icon {
    height: 1.73rem;
    width: 1.75rem;
}

.chat-svg-icon:hover,
.settings-svg-icon:hover {
    background: #00000017;
}

/* Tooltip styling */
.sidebar-tooltip, .chat-tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 8px 12px;
    border-radius: 8px;
    white-space: nowrap;
    font-size: 0.9rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
    z-index: 10000; /* Ensure it's above other elements */
    opacity: 1; /* Default to visible for testing */
    pointer-events: auto; /* Allow hover for debugging */
    animation: fadeInSidebar 0.3s forwards;
}

/* Tooltip for Close Sidebar icon */
.sidebar-tooltip {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.sidebar-tooltip::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
}

/* Tooltip for New Chat icon in top-chat-options */
.chat-tooltip {
    top: 100%; /* Positioned below the icon */
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

.chat-tooltip::before {
    content: "";
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
}

/* Tooltip for Open Sidebar icon in top-chat-options */
.sidebar-open-tooltip {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.sidebar-open-tooltip::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
}

.fade-in {
    animation: fadeInSidebar 0.3s forwards;
}

/* Keyframes for the fade-in effect */
@keyframes fadeInSidebar {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.sidebar-history-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto; /* Enables vertical scrolling */
    max-height: calc(100vh - 85px); /* Adjust height to fit within the sidebar */
    scrollbar-width: thin; /* For Firefox: Thin scrollbar */
    scrollbar-color: transparent transparent; /* Default scrollbar hidden */
    width: 100%;
    margin-top: 10px;
}

.sidebar-history-content:hover {
    scrollbar-color: #9b9c9c #00000000; /* Lighter scrollbar thumb and track */
}

.sidebar-history-content::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    background-color: transparent; /* Hide the scrollbar background by default */
}

.sidebar-history-content::-webkit-scrollbar-thumb {
    background-color: transparent; /* Hide the scrollbar thumb by default */
    border-radius: 4px; /* Rounded edges for the thumb */
}

.sidebar-history-content:hover::-webkit-scrollbar-thumb {
    background-color: #d6d6d600; /* Lighter gray scrollbar thumb on hover */
}

.sidebar-history-content::-webkit-scrollbar-track {
    background-color: #f9f9f9; /* Very light gray track on hover */
}

/*

.sidebar-history-content::before {
  content: '';
  display: block;
  height: 2px;
  background: linear-gradient(180deg,#cecece 50%,#fff 0);
}

*/

.sidebar-history-content p {
    font-size: 1rem;
    color: #555;
    text-align: center;
    margin: 20px 0;
    line-height: 1.5;
}

.sidebar-history-content ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.history-item {
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 25px;
    overflow: hidden;
    padding: 8px;
    white-space: nowrap;
    width: 210px;
    transition: background 0.2s ease-out;
/*    opacity: 0; */
}

/*

.history-heading {
    opacity: 0;
}

*/

.history-item:hover {
    background: #d5d5d5;
}

.sidebar-history-content h4 {
    font-size: 13px;
    margin-bottom: 10px;
    user-select: none;
    user-select: none;
    border-left: 2px solid #9e9e9e;
    padding-left: 5px;
    color: #353535;
}

.sidebar-history-content .history-item,
.sidebar-history-content .history-heading {
  opacity: 0;
}

/* Visible state when sidebar is opened normally */
.sidebar-history-content.open .history-item,
.sidebar-history-content.open .history-heading {
  opacity: 1;
}

