/* Hero section */
.hero-section-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.hero-2-image {
    position: absolute;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
}

.hero-2-text-container {
    top: 50%;
    left: 8%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateY(-50%);
}

.hero-2-title {
    text-align: left;
    color: #F5F5F7;
    font-size: 70px;
    font-weight: 300;
    letter-spacing: -1px;
    user-select: none;
}

.hero-2-title-gradient {
    background-image: linear-gradient(146deg, #55aee2, hsl(332.76deg 48.88% 50.28%));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.hero-button-container {
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background: #b6b6b6bf;
    border-radius: 250px;
    box-shadow: 0 2px 4px -1px #394c6026;
    display: flex;
    justify-content: space-between;
    padding: 15px 17px 15px 35px;
    align-items: center;
    gap: 30px;

    margin-top: 2em;
}

.hero-button-container-text-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 16px;
    text-align: left;
}

.hero-button-container-text-bold {
    font-weight: 500;
}

.hero-button-container-text {
    font-weight: 300;
}

.hero-button {
/*  margin-top: 2em; */

    border-radius: 110px;
}





@media (orientation: portrait) {
    .hero-2-image {
        left: 50%;
        bottom: 0%;
        top: auto;
        height: 120vh;
        transform: translateX(-48%);
    }

    .hero-2-text-container {
        align-items: center;
        left: auto;
        top: 25%;
    }

    .hero-2-title {
        font-size: 60px;
    }

    .hero-button-container {
        align-items: center;
        gap: 15px;
        margin-top: 2em;
        position: absolute;
        bottom: 20px;
    }
}

/* Mobile */
@media (max-width: 480px) {
    .hero-2-title {
        font-size: 50px;
    }

    .hero-button-container-text-wrapper {
        font-size: 13px;
    }

    .hero-button {
        font-size: 13px;
    }
}

/* Small Mobile */
@media (max-width: 380px) {
    .hero-2-title {
        font-size: 40px;
    }

    .hero-button-container {
        padding: 12px 13px 12px 24px;
    }

    .hero-button-container-text-wrapper {
        font-size: 11px;
    }

    .hero-button {
        font-size: 11px;
        padding: 0 18px;
        height: 35px;
    }
}