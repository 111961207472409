/* Outer container: occupies 93% of viewport height */
.results-page-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: Inter,sans-serif;
  gap: 60px;
  height: 100%;
  opacity: 0;
  padding: 30px;
  width: 100%;
}

/* Top Section */
.results-page__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.results-page__title {
  margin: 0;
  font-size: 2em;
}

.results-page__info {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  font-size: 1.2em;
}

.results-page__score-info,
.results-page__time-info,
.results-page__pass-info {
  padding: 5px 10px;
  border-radius: 10px;
  user-select: none;
}

/* Middle Section */
.results-page__middle {
  align-items: center;
  display: flex;
  gap: 50px;
  justify-content: center;
}

/* Circular Progress Indicator */
.circular-progress {
  height: 200px;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 200px;
}

.results-page__progress {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  transform: rotate(-90deg);
}

.progress-ring__background {
  stroke: #eee;
}

.progress-ring__progress {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
}

.progress-ring__text {
  font-size: 45px;
  font-weight: 600;
  fill: #333;
  user-select: none;
}

/* Question Preview Box */
.results-page__question-preview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 700px;
}

/* Bottom Section - Navigation Circles */

.results-page__bottom-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.results-page__navigation-help {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.results-page__navigation-help-text {
  color: #929293;
  font-weight: 600;
  user-select: none;
}

.results-page__navigation-icons {
  display: flex;
  gap: 10px;
  border-left: 2px solid #ddd;
  padding-left: 10px;
}

.results-page__navigation-icon {
  font-size: 24px;
  color: #646464b3;
  transition: transform 0.1s ease-in-out;
}

.results-page__navigation-icon.active {
  transform: scale(0.9);
}

.results-page__navigation {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.nav-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f2f2f2;
  border: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.4em;
  transition: transform 0.2s;
  user-select: none;
}

.nav-circle:hover {
  transform: scale(1.1);
}

.nav-circle--correct {
  background: linear-gradient(135deg, #d1f7c4, #a8e6a1);
  border: none;
}

.nav-circle--incorrect {
  background: linear-gradient(135deg, #ff0000a6, #ff919d);
  border: none;
}

.nav-circle--active {
  transform: scale(0.9);
  pointer-events: none;
}
