.classroom-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.classroom-content-inner {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.top-classroom-options {
  align-items: center;
  display: flex;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 999999;
}

.classroom-content {
  align-items: center;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 12px;
  padding: 10px;
  position: relative;
  transition: flex-grow .3s ease;
}

.studio-logo-hidden {
  display: none;
}

.hidden {
  display: none;
}

.chat-icon-container {
  position: relative; /* Ensures tooltip aligns properly */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loading Spinner */

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

.lds-spinner {
  color: #45494a; /* change color if needed */
  display: inline-block;
  position: relative;
  width: 30px;  /* reduced from 40px */
  height: 30px; /* reduced from 40px */
}

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}

.lds-spinner div {
  transform-origin: 15px 15px; /* reduced from 20px 20px */
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.2px;    /* reduced from 1.6px */
  left: 13.8px;  /* reduced from 18.4px */
  width: 2.4px;  /* reduced from 3.2px */
  height: 6.6px; /* reduced from 8.8px */
  border-radius: 20%;
  background: currentColor;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Loading Ellipsis */


.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: rgb(78, 78, 78);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
