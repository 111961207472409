@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.0/css/all.min.css');

html {
    overflow-x: hidden;
    font-size: 100%;
}

html, body, h1, h2, h3, h4, h5, p, span, button {
    font-family: inter, sans-sarif;
}

a, a:hover, a:active {
    color: transparent;
    text-decoration: none;
}

:root {
    --dark-text: rgb(13, 18, 22);
    --faded-dark-text: #55595B;
    --light-grey-button: rgba(64,87,109,.07);
    --light-grey-background: #f2f2f2;
}

.logo-container {
    position: relative;
}

.navbar-logo {
    padding: 0 30px 0 0;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    z-index: 2;
    position: relative;
}

.navbar-logo-x {
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    left: 90px;
    transform: translateY(-50%);
    top: 50%;
    z-index: 1;
    font-size: 48px;
    color: #f0f0f0;
    font-style: italic;
    display: none;
}

.text-gradient {
    background-image: linear-gradient(146deg, #55aee2, hsl(332.76deg 48.88% 50.28%));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

button {
    font-size: 14px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
}

.button-primary {
    position: relative;
    background-color: transparent;
    background: #0078eb;
    transition: background 0.2s ease;
}

.button-primary:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, #b4b4b433, transparent);
    transition: left 0.5s ease;
}

.button-primary:hover:before {
    left: 100%;
}

.button-primary:hover {
    background-color: #0078eb;
}

.button-primary:focus {
    background: linear-gradient(12deg, #205479, #205479);
}

.button-secondary {
    color: #0d1216;
    background-color: var(--light-grey-button);
    border: solid 1px rgba(13, 18, 22, 0);
    transition: background-color 0.2s ease;
}

.button-secondary:hover {
    background-color: rgba(57,76,96,.15);
}

.grey-hover-effect {
    transition: background-color 0.2s ease;
}

.grey-hover-effect:hover {
    background-color: var(--light-grey-button);
    border: solid 1px rgba(13, 18, 22, 0);
}

.tooltip {
    visibility: hidden;
    background-color: var(--dark-text);
    font-size: 12px;
    font-weight: 500;
    color: white;
    text-align: center;
    text-wrap: nowrap;
    padding: 8px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}