/* Overall container */
.tf-result {
  font-family: Inter, sans-serif;
  position: relative;
  opacity: 0;
  border-radius: 10px;
}

/* Header styling */
.tf-result__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tf-result__header h2 {
  margin: 0;
  font-size: 1.5em;
}

/* Question text styling */
.tf-result__question {
  font-size: 1.1em;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

/* Options container */
.tf-result__options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Base option styling */
.tf-result__option {
  padding: 10px;
  border-radius: 10px;
  width: 180px;
  font-size: 20px;
  text-align: center;
  cursor: default;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  background: var(--background-color, #f2f2f2);
  box-shadow: 0 5px 15px var(--shadow-color);
}

/* Difficulty-specific styling for options */
.tf-result__option-novice {
  --background-color: linear-gradient(135deg, #d6c6ff, #b19cd9);
  --selected-background: #967bb6;
  --shadow-color: rgba(178, 137, 255, 0.4);
  --selected-shadow: rgba(150, 123, 182, 0.6);
}

.tf-result__option-apprentice {
  --background-color: linear-gradient(135deg, #cce5ff, #9cf);
  --selected-background: #70bfff;
  --shadow-color: rgba(112, 191, 255, 0.4);
  --selected-shadow: rgba(96, 170, 235, 0.6);
}

.tf-result__option-adept {
  --background-color: linear-gradient(135deg, #b7f0e0, #91d8cc);
  --selected-background: #67b9a8;
  --shadow-color: rgba(103, 185, 168, 0.4);
  --selected-shadow: rgba(75, 153, 140, 0.6);
}

.tf-result__option-expert {
  --background-color: linear-gradient(135deg, #ffbdbd, #ff9999);
  --selected-background: #f46666;
  --shadow-color: rgba(244, 102, 102, 0.4);
  --selected-shadow: rgba(225, 80, 80, 0.6);
}

.tf-result__option-master {
  --background-color: linear-gradient(135deg, #fff4cc, #ffe066);
  --selected-background: #ffc107;
  --shadow-color: rgba(255, 193, 7, 0.4);
  --selected-shadow: rgba(230, 170, 0, 0.6);
}

/* Highlight: if selected and wrong */
.tf-result__option--selected-wrong {
  background: #ff0000a6 !important;
  border: none !important;
  box-shadow: none !important;
}

/* Highlight: if correct (or selected and correct) */
.tf-result__option--correct,
.tf-result__option--selected-correct {
  background: #d1f7c4 !important;
  border: none !important;
  box-shadow: none !important;
  transform: scale(0.95);
}

/* Option key styling */
.tf-result__option-key {
  font-weight: bold;
  color: var(--key-color);
}

/* Explanation container */
.tf-result__explanation {
  margin-top: 20px;
  border-top: 2px solid #ddd;
  padding-top: 20px;
  text-align: center;
  color: #555;
}
