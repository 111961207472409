/* Overall container */
.mc-result {
    font-family: Inter, sans-serif;
    position: relative;
    opacity: 0;
}

/* Header styling */
.mc-result__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc-result__header h2 {
  margin: 0;
  font-size: 1.5em;
}

.mc-result__icon {
    font-size: 2.5em;
    position: absolute;
    right: 0px;
    top: -5px;
}

.mc__correct-answer {
    color: green;
}

.mc__incorrect-answer {
    color: red;
}

/* Question text styling */
.mc-result__question {
    font-size: 1.1em;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
}

/* Body layout */
.mc-result__body {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
}

/* Options container */
.mc-result__options {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Base Option styling */
.mc-result__option {
  background: var(--background-color, #f2f2f2);
  color: var(--text-color);
  text-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  cursor: default;
  display: flex;
  font-size: 1em;
  height: 25px;
  overflow: hidden;
  padding: 10px;
  width: 250px;
}

/* Difficulty-specific variable definitions */
.mc-result__option-novice {
  --background-color: linear-gradient(135deg, #d6c6ff, #b19cd9);
  --selected-background: #967bb6;
  --selected-shadow: rgba(150, 123, 182, 0.6);
  --key-color: #6b518e;
}

.mc-result__option-apprentice {
  --background-color: linear-gradient(135deg, #cce5ff, #9cf);
  --selected-background: #70bfff;
  --selected-shadow: rgba(96, 170, 235, 0.6);
  --key-color: #4a93cb;
}

.mc-result__option-adept {
  --background-color: linear-gradient(135deg, #b7f0e0, #91d8cc);
  --selected-background: #67b9a8;
  --selected-shadow: rgba(75, 153, 140, 0.6);
  --key-color: #4a998a;
}

.mc-result__option-expert {
  --background-color: linear-gradient(135deg, #ffbdbd, #ff9999);
  --selected-background: #f46666;
  --selected-shadow: rgba(225, 80, 80, 0.6);
  --key-color: #d94343;
}

.mc-result__option-master {
  --background-color: linear-gradient(135deg, #fff4cc, #ffe066);
  --selected-background: #ffc107;
  --selected-shadow: rgba(230, 170, 0, 0.6);
  --key-color: #c79900;
}

/* Highlight: if selected and wrong - override background, border, and remove shadow */
.mc-result__option--selected-wrong {
  background: #ff0000a6 !important;
  border: none !important;
  box-shadow: none !important;
}

/* Highlight: if correct (or selected and correct) - override background, border, and remove shadow */
.mc-result__option--correct,
.mc-result__option--selected-correct {
  background: #d1f7c4 !important;
  border: none !important;
  box-shadow: none !important;
  transform: scale(0.98);
}

/* Option key styling */
.mc-result__option-key {
  font-weight: bold;
  margin-right: 5px;
}

/* Explanation container */
.mc-result__explanation {
  flex: 1;
  border-left: 2px solid #ddd;
  padding-left: 20px;
  font-size: 1.1em;
  color: #555;
}