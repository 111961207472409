/* Plans section */
.plans-section {
    padding: 10vh 5.4vw;
}

.plans-section-title {
    font-size: 80px;
    font-weight: 700;
    color: var(--dark-text);
    text-align: left;
    opacity: 0; 
}

.plan-table-container {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: flex-start;
    padding: 50px 0;
}

.plan-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 390px;
    height: fit-content;
    border-radius: 14px;
    padding: 40px 34px;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-text);
    opacity: 0; 
}

.LP-pro-plan-card {
    background: var(--light-grey-background);
    position: relative;
    z-index: 9;
}

.LP-plan-badge-container {
    height: 22px;
    margin-bottom: 12px;
    border-radius: 6px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    overflow: hidden
}

.LP-plan-badge-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, transparent, #ffffff38, #ffffff61, #ffffff4f, transparent);
    z-index: 5;
    animation: glimmer-loop 4s infinite ease-in-out;
}

@keyframes glimmer-loop {

    0%,
    89% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}

.LP-plan-most-popular-text {
    text-align: center;
    padding: 8px 8px;
    background: rgb(0 0 0 / 30%);
    border-radius: 5px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    z-index: 2;
    user-select: none;
}

.plan-card-top-section {
    height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.plan-title {
    text-align: start;
    font-size: 24px;
    font-weight: 600;
    user-select: none;
}

.plan-body {
    text-align: left;
    margin-top: 12px;
    opacity: 0.7;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    user-select: none;
}

.price-and-button-container {
    width: -webkit-fill-available;
}

.plan-price-container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
}

.plan-price {
    text-align: left;
    font-size: 36px;
    font-weight: 700;
}

.per-month-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    opacity: 0.7;
}

.plan-button {
    width: -webkit-fill-available;
    margin-top: 16px;
    font-size: 15px;
    color: #fff;
    padding: 5px 0px;
    height: 55px;
    background: #0078eb;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.plan-includes-container {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 12px;
    text-align: left;
}

.product-subheading {
    font-weight: 600;
    font-size: 17px;
    margin-top: 10px;
    color: #0d1216;
    user-select: none;
    line-height: 24px;
}

.includes-item {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
    gap: 10px;
    color: #0d1216;
    margin-left: 15px;
}

.LP-no-access-feature {
    opacity: 0.4;
}

.includes-text {
    color: #0d1216;
    user-select: none;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.includes-text span {
    font-weight: 600;
}







.extra-feature-info {
    color: #0d1216c4;
}

.extra-feature-info:hover {
    color: #0d1216;
}

.extra-feature-info-youtube-container {
    display: inline-block;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.youtube-video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Common styles for all feature videos */
.youtube-feature-video,
.webpage-summaries-feature-video,
.file-analysis-feature-video {
    position: absolute;
    bottom: 26px;
    width: 480px;
    height: 300px;
    z-index: 1000;
    border-radius: 15px;
    background-color: #000;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: visibility 0.3s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    box-shadow: 0 0px 500px rgb(34 97 131 / 28%);
}

.youtube-feature-video-element, .webpage-summaries-feature-video-element, .file-analysis-feature-video-element {
    border-radius: 15px;
    box-shadow: 2px 4px 12px #00000033;
}

/* Hover effect for YouTube feature */
.extra-feature-info-youtube-container:hover .youtube-feature-video {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

/* Webpage Summaries */
.extra-feature-info-webpage-summaries-container {
    display: inline-block;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.webpage-summaries-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Hover effect for Webpage summaries */
.extra-feature-info-webpage-summaries-container:hover .webpage-summaries-feature-video {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

/* File Analysis */
.extra-feature-info-file-analysis-container {
    display: inline-block;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.file-analysis-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Hover effect for File analysis */
.extra-feature-info-file-analysis-container:hover .file-analysis-feature-video {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.LP-pricing-tokens-icon {
    font-size: 21px;
}

.LP-pricing-tick {
    font-size: 20px;
}

.LP-no-access-x {
    font-size: 20px;
}







@media (max-width: 1024px) {
    .plans-section {
        padding: 5%;
        margin-top: 7vh;
        gap: 3vh;
    }
}

@media (min-width: 541px) and (max-width: 912px) {
    .plans-section {
        padding: 2%;
        margin-top: 7vh;
        gap: 3vh;
    }

    .plans-section-title {
        font-size: 3rem;
        text-align: center;
    }

    .plan-table-container {
        padding-top: 3vh;
        gap: 12px;
    }

    .plan-card {
        width: -webkit-fill-available;
        padding: 1.5rem;
    }

    .plan-card-top-section {
        height: 17rem;
    }

    .plan-title,
    .plan-price {
        font-size: 2.5rem;
    }

    .plan-body {
        font-size: 0.9rem;
    }

    .plan-button {
        padding: 15px;
    }
    
    .plan-includes-container {
        font-size: 0.9rem;
    }
}

@media (max-width: 540px) {
    .plans-section {
        padding: 5%;
        margin-top: 7vh;
        gap: 3vh;
    }

    .plans-section-title {
        font-size: 3rem;
        text-align: left;
    }

    .plan-table-container {
        flex-direction: column;
        padding-top: 3vh;
    }

    .plan-card {
        width: -webkit-fill-available;
    }
}