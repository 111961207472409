/* Copilot Section #2 (airpod pro 2 & 4) */
.copilot-section {
    position: relative;
}

.copilot-container {
    gap: 20px;
}

.copilot-title-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    padding: 0 5.4vw;
    text-align: left;
    width: 100%;
    position: relative;
    z-index: 3;
}

.copilot-content-container {
    display: flex;
    color: var(--faded-dark-text);

    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 5.4vw;
    position: relative;
    z-index: 3;
    height: auto;
    width: -webkit-fill-available;
}

.copilot-image-container {
    height: 580px;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copilot-image {
    height: 580px;
    width: auto;
    position: absolute;
    transform-origin: center center;
}

.copilot-voice-image {
    height: 520px;
}

.copilot-text-element {
    position: relative;
    z-index: 3;
    font-size: 22px;
    color: var(--faded-dark-text);
    padding: 50px 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 50px;
    text-align: left;
    font-weight: 700;
    width: -webkit-fill-available;
}

.copilot-scroll-text-left,
.copilot-scroll-text-right {
    color: var(--faded-dark-text);
    width: 302px;
    text-align: left;
    font-size: 22px;
    font-weight: 400;
}

.copilot-scroll-text-left {
    position: absolute;
    left: 7%;
}

.copilot-scroll-text-right {
    position: absolute;
    right: 7%;
}







/* ===================== */
/* Landscape Tablet */
/* ===================== */
/*
@media (max-width: 1435px) {
    .copilot-image {
        height: 500px;
    }

    .copilot-scroll-text-left, .copilot-scroll-text-right {
        font-size: 18px;
        width: 250px;
    }
}
*/



/* ===================== */
/* Nest Hub Max */
/* ===================== */
@media (max-width: 1300px) {
    .copilot-scroll-text-left {
        left: 4%;
    }

    .copilot-scroll-text-right {
        right: 4%;
    }
}


/* ===================== */
/* Nest Hub */
/* ===================== */
@media (max-width: 1100px) {
    .copilot-container {
        gap: 0px;
        justify-content: flex-start;
    }

    .copilot-image-container {
        height: 450px;
    }

    .copilot-image {
        height: 400px;
    }

    .copilot-scroll-text-left, .copilot-scroll-text-right {
        font-size: 18px;
        width: 250px;
    }

    .copilot-scroll-text-left {
        left: 4%;
    }

    .copilot-scroll-text-right {
        right: 4%;
    }
}


/* ===================== */
/* Portait Mode */
/* ===================== */
@media (max-width: 1100px) and (orientation: portrait)  {
    .copilot-container {
        gap: 0px;
        align-items: center;
        justify-content: center;
    }

    .copilot-title-wrapper {
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: auto;
        flex-direction: column;
        text-align: center;
        padding: 0;
    }

    .feature-title {
        font-size: 40px;
    }

    .feature-title-2 {
        font-size: 50px;
    }

    .copilot-image-container {
        height: 650px;
    }

    .copilot-image {
        height: 540px;
    }

    .copilot-scroll-text-left, .copilot-scroll-text-right {
        font-size: 22px;
        left: 50%;
        right: auto;
        top: auto;
        bottom: -14%;
        transform: translateX(-50%);
    }

    .LP-copilot-sidebar-text {
        width: 470px;
    }

    .LP-copilot-voice-text {
        width: 480px;
    }

    .LP-copilot-research-text {
        width: 500px;
    }

    .LP-copilot-youtube-text {
        width: 490px;
    }

    .LP-copilot-quick-actions-text {
        width: 440px;
    }

    .LP-copilot-artist-text {
        width: 450px;
    }
}

/* ===================== */
/* Portait iPad Pro */
/* ===================== */
@media (max-width: 1100px) and (min-height: 1200px)  {
    .copilot-title-wrapper {
        width: 500px;
    }

    .feature-title {
        font-size: 50px;
    }

    .feature-title-2 {
        font-size: 60px;
    }

    .copilot-image-container {
        height: 800px;
    }

    .copilot-image {
        height: 700px;
    }

    .copilot-scroll-text-left, .copilot-scroll-text-right {
        bottom: -16%;
        font-size: 28px;
    }

    .LP-copilot-research-text {
        width: 520px;
    }
}


/* ===================== */
/* Portrait Tablet */
/* ===================== */
@media (max-width: 750px) {
    .copilot-title-wrapper {
        width: 400px;
    }

    .copilot-image-container {
        height: 450px;
    }

    .copilot-image {
        height: 420px;
    }

    .copilot-scroll-text-left, .copilot-scroll-text-right {
        bottom: -18%;
    }
}


/* ===================== */
/* Large Mobile */
/* ===================== */
@media (max-width: 600px) {
    .copilot-image-container {
        height: 420px;
    }

    .copilot-image {
        height: 360px;
    }

    .copilot-scroll-text-left, .copilot-scroll-text-right {
        font-size: 16px;
    }

    .LP-copilot-sidebar-text {
        width: 340px;
    }

    .LP-copilot-voice-text {
        width: 350px;
    }

    .LP-copilot-research-text {
        width: 360px;
    }

    .LP-copilot-youtube-text {
        width: 360px;
    }

    .LP-copilot-quick-actions-text {
        width: 320px;
    }

    .LP-copilot-artist-text {
        width: 330px;
    }

}


/* ===================== */
/* Surface Duo */
/* ===================== */
@media 
(min-width: 500px) and 
(max-width: 600px) and 
(min-height: 650px) and 
(max-height: 750px) {
    .feature-title {
        font-size: 30px;
    }

    .feature-title-2 {
        font-size: 40px;
    }

    .copilot-image-container {
        height: 385px;
    }

    .copilot-image {
        height: 360px;
    }
}


/* ===================== */
/* Small Mobile */
/* ===================== */
@media (max-width: 380px) {
    .feature-title {
        font-size: 30px;
    }

    .feature-title-2 {
        font-size: 35px;
    }

    .copilot-image-container {
        height: 330px;
    }

    .copilot-image {
        height: 320px;
    }

    .copilot-scroll-text-left, .copilot-scroll-text-right {
        bottom: -28%;
    }

    .LP-copilot-sidebar-text {
        width: 280px;
    }

    .LP-copilot-voice-text {
        width: 260px;
    }

    .LP-copilot-research-text {
        width: 300px;
    }

    .LP-copilot-youtube-text {
        width: 260px;
    }

    .LP-copilot-quick-actions-text {
        width: 250px;
    }

    .LP-copilot-artist-text {
        width: 260px;
    }
}

